<!-- 拼团 -->
<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import TablePage from '@/components/tablePage'
import {
  fightGroupList,
  fightGroupAudit,
  fightGroupCopyActivity,
  fightGroupUpdateIsEnable,
  fightGroupUpdateDelete
} from '@/api/O2OMall/marketing/fightGroup' //接口api
export default {
  name: 'fightGroup',
  components: { TablePage },
  data () {
    return {
      options: {
        mutiSelect: true, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        labelWidth: '80',
        getListApi: fightGroupList,
        body: {},
        listNo: true, // 序号
        title: '拼团',
        check: [], // 选中数据
        rowKey: 'fightGroupId',
        search: [
          {
            label: '创建时间',
            type: 'datetimerange',
            model: '',
            noReset: true,
            filterFnc: ([a, b] = []) => ({ activityBeginTime: a, activityEndTime: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'month',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          // { type: "input", label: '', tip:"全部/活动编号/活动名称", model: "", filter: "query" },
          {
            type: 'filters',
            tip: '全部/活动编号/活动名称',
            isRestore: true,
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'activityNos', label: '活动编号' },
              { filter: 'activityNames', label: '活动名称' }
            ]
          },
          {
            type: 'local',
            label: '启用状态',
            model: '',
            filter: 'isEnables',
            seniorSearch: true,
            option: {
              data: [
              { label: '启用', value: '1' },
              { label: '禁用', value: '0' }
            ],
              label: 'label',
              value: 'value',
              multiple: true
            }
          },
          this.$select({
            key: 'bill_status',
            option: {
              label: '方案状态',
              seniorSearch: true,
              option: { multiple: true }
            }
          }),
          // , option: { multiple: true }
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        buttons: [
          {
            click: 'add',
            label: '新增',
            icon: 'el-icon-plus',
            type: 'primary',
            disabled: false
          },
          {
            click: 'audit',
            label: '审核',
            type: 'success',
            btnType: 'dropdown',
            other: [
              {
                click: 'redoAudit',
                label: '反审核'
              }
            ],
            disabled: () => !this.options?.check?.length
          },
          {
            click: 'enable',
            label: '启用',
            type: 'warning',
            btnType: 'dropdown',
            other: [
              {
                click: 'redoEnable',
                label: '禁用'
              }
            ],
            disabled: () => !this.options?.check?.length
          },
          {
            click: 'copy',
            label: '复制',
            icon: 'el-icon-document-copy',
            type: 'success',
            disabled: () => !this.options?.check?.length
          },
          {
            click: 'del',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete',
            disabled: () => !this.options?.check?.length
          },
          {
            click: 'refresh',
            right: true,
            label: '刷新',
            icon: 'el-icon-refresh',
            type: ''
          }
        ],
        columns: [
          {
            prop: 'activityNo',
            label: '活动编号',
            type: 'link',
            click: 'routerLink',
            minWidth: 200
          },
          {
            prop: 'activityName',
            label: '活动名称',
            minWidth: 155
          },
          {
            prop: 'auditStatus',
            label: '审核状态',
            minWidth: 160,
            formatter: v => (v == 0 ? '未审核' : '已审核')
          },
          {
            prop: 'orderMoney',
            label: '订单实付金额(元)',
            minWidth: 180
          },
          {
            prop: 'orderCount',
            label: '付款订单数',
            minWidth: 100
          },
          // {
          //     prop: "ordersCount",
          //     label: "付款人数",
          //     minWidth: 160,
          // },
          {
            prop: 'executeStatus',
            label: '审核状态',
            minWidth: 160,
            formatter: v => (v == 0 ? '未执行' : v == 1 ? '执行中' : '已结束')
          },
          {
            prop: 'activityBeginTime',
            label: '生效时间',
            minWidth: 160
          },
          {
            prop: 'activityEndTime',
            label: '失效时间',
            minWidth: 160
          },
          {
            prop: 'isEnable',
            label: '启用状态',
            minWidth: 160,
            formatter: v => (v ? '启用' : '禁用')
          },
          {
            prop: 'createBy',
            label: '创建人',
            minWidth: 160
          },
          {
            prop: 'createTime',
            label: '创建时间',
            minWidth: 160
          },
          {
            prop: 'updateBy',
            label: '修改人',
            minWidth: 160
          },
          {
            prop: 'updateTime',
            label: '修改时间',
            minWidth: 160
          },
          {
            prop: 'remark',
            label: '备注',
            minWidth: 160
          }
        ],
        list: []
      }
    }
  },
  methods: {
    async handleEvent (type, row) {
      const selectData = this.options.check
      switch (type) {
        case 'routerLink':
          {
            this.$router.push({
              path: '/O2OMall/marketing/fightGroup/fightGroupDetail',
              query: {
                fightGroupId: row.fightGroupId
              }
            })
          }
          break
        case 'add':
          {
            this.$router.push({
              name: 'FightGroupDetail'
            })
          }
          break
        case 'audit':
          {
            if (!selectData.length) return
            this.handleBillStatus('审核', selectData)
          }
          break
        case 'redoAudit':
          {
            if (!selectData.length) return
            this.handleBillStatus('反审核', selectData)
          }
          break
        case 'enable':
          {
            if (!selectData.length) return
            this.handleBillStatus('启用', selectData)
          }
          break
        case 'redoEnable':
          {
            if (!selectData.length) return
            this.handleBillStatus('禁用', selectData)
          }
          break
        case 'del':
          {
            if (!selectData.length) return
            this.handleBillStatus('删除', selectData)
          }
          break
        case 'copy':
          {
            if (!selectData.length) return
            this.handleBillStatus('复制', selectData)
          }
          break
        default:
          break
      }
    },
    seniorSearch () {},
    handleBillStatus (command, selectData, url) {
      let name = ''
      let auditStatus = ''
      let isEnable = ''
      if (command === '反审核') {
        name = '反审核'
        auditStatus = '0'
      } else {
        if (command === '审核') {
          name = '审核'
          auditStatus = '1'
        } else if (command === '删除') {
          name = '删除'
        } else if (command === '复制') {
          name = '复制'
        } else if (command === '启用') {
          name = '启用'
          isEnable = true
        } else if (command === '禁用') {
          name = '禁用'
          isEnable = false
        }
      }
      const fightGroupId = selectData.map(i => i.fightGroupId)
      const $this = this
      this.$modal.confirm(`是否确认${name}活动`).then(() => {
        if (['启用', '禁用'].includes(name)) {
          fightGroupUpdateIsEnable({
            fightGroupIds: [...fightGroupId],
            isEnable: isEnable
          }).then(res => {
            $this.$nextTick(() => {
              $this.options.check = []
            })
            setTimeout(() => {
              $this.$refs.tablePage.getList()
            }, 500)
            if (isEnable) {
              $this.$modal.msgSuccess('启用成功')
            } else {
              $this.$modal.msgSuccess('禁用成功')
            }
          })
        } else if (['复制', '删除'].includes(name)) {
          let url =
            name == '复制' ? fightGroupCopyActivity : fightGroupUpdateDelete
          url(fightGroupId).then(res => {
            setTimeout(() => {
              $this.$refs.tablePage.getList()
            }, 500)
            $this.$modal.msgSuccess('操作成功')
          })
        } else {
          fightGroupAudit({
            fightGroupIds: [...fightGroupId],
            auditStatus
          }).then(() => {
            $this.$nextTick(() => {
              $this.options.check = []
            })
            $this.$modal.msgSuccess(`${name}成功`)
            setTimeout(() => {
              $this.$refs.tablePage.getList()
            }, 500)
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped></style>
