<!-- 入会有礼 -->
<template>
  <div class="wrap">
    <TablePage
      ref="tablePage"
      v-show="showList"
      v-model="options"
      @handleEvent="handleEvent"
    />
    <TablePage
      ref="tablePageDetail"
      v-if="!showList"
      class="detail"
      v-model="optionsDetail"
      @handleEvent="handleEventDetail"
    />
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import {
  inviteGiftList,
  inviteGiftUpdateIsEnable,
  inviteGiftAudit,
  inviteGiftDelete,
  inviteGiftCopy,
  getMarketingInviteGiftLog,
} from '@/api/O2OMall/marketing/inviteGift' //接口api
// import StockQueryDetail from './components/stockQueryDetail.vue' //商品库存详情明细对话框组件
export default {
  name: 'StockQuery',
  components: { TablePage,  },
  dicts: ["bill_status"],
  data () {
    return {
      showList: true,
      openStockQuery: false,
      goodsDetailData: {},
      options: {
        radioSelect: true,
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        body: {},
        rowKey: "billId",
        check: [],
        title: '邀请有礼',
        tableTitle: '邀请有礼',
        search: [
          {
            label: "创建时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ queryBegTime: a, queryEndTime: b }),
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'month',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: "filters",
            tip: "全部/活动编号/活动名称",
            isRestore:true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "billNos", label: "活动编号" },
              { filter: "billNames", label: "活动名称" },
            ],
          },
          this.$select({ key: "executionStatus",option: { seniorSearch: true, option: { multiple: true }}}),
          this.$select({ key: "isStopState",option: { seniorSearch: true, option: { multiple: true }}}),
          this.$select({ key: "bill_status",option: { seniorSearch: true, option: { multiple: true }}}),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
            disabled: false,
          },
          {
            click: "audit",
            label: "审核",
            type: "success",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "recon",
            label: "启用",
            type: "warning",
            btnType: "dropdown",
            other: [
              {
                click: "redoRecon",
                label: "禁用",
              },
            ],
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "copy",
            label: "复制",
            icon: "el-icon-document-copy",
            type: "success",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "oepnDetail",
            label: "邀请有礼记录",
            type: "primary",
            right: true,
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        getListApi: inviteGiftList,
        columns: [
          {
            prop: "billNo",
            label: "活动编号",
            type: "link",
            click: 'routerLink',
            minWidth: 155,
          },
          {
            prop: "billName",
            label: "活动名称",
            minWidth: 155,
          },
          {
            prop: "billStatus",
            label: "活动状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "executionStatusName",
            label: "执行状态",
            minWidth: 155,
            formatter: (v, row) => {
              let currentTime = new Date().getTime()
              let startTime = new Date(row.begTime).getTime()
              let endTime = new Date(row.endTime).getTime()
              return  currentTime < startTime? '未开始' : currentTime > endTime? '已失效' : '进行中'
            },
          },
          {
            prop: "isStop",
            label: "是否停用",
            formatter: (v, row) => row.isStop?  '禁用' : '启用',
            minWidth: 120,
          },
          {
            prop: "begTime",
            label: "生效时间",
            width: 170,
          },
          {
            prop: "endTime",
            label: "失效时间",
            width: 170,
          },
          {
            prop: "billRemark",
            label: "备注",
            minWidth: 120,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 160,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 160,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 160,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 160,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
          },
        ],
        list:[{
          goodsNo: '000'
        }]
      },
      optionsDetail: {
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        body: {},
        title: '邀请有礼明细',
        tableTitle: '邀请有礼明细',
        rowKey: 'billId',
        // list: [],
        defaultBody: {
          billId: '',
        },
        getListApi: getMarketingInviteGiftLog,
        search: [
          {
            label: "领取时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'month',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            tip: '全部/活动编号/活动名称',
            isRestore: true,
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'billNos', label: '活动名称' },
              { filter: 'billNos', label: '推荐人' },
              { filter: 'billNos', label: '受邀人' },
            ]
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          {
            click: "close",
            label: "关闭",
            type: "primary",
            right: true,
          },
        ],
        columns: [
          { label: '活动编号', prop: 'billNo', fixed: true, minWidth: 120 },
          { label: '活动名称', prop: 'billName', fixed: true, minWidth: 120 },
          { label: '推荐人名称', prop: 'recommendInviteVipName', minWidth: 100 },
          { label: '推荐人卡号', prop: 'recommendInviteVipNo', minWidth: 120 },
          { label: '推荐人手机', prop: 'recommendInviteVipTel', minWidth: 100 },
          { label: '推荐人奖品', prop: 'recommendInviteRewardType',
            formatter: (v, row) =>  {
              let obj = {
                1: '积分',
                2: '余额',
                3: '优惠券',
              }
              return  obj[row.recommendInviteRewardType]
            },
            minWidth: 80 },
          { label: '受邀人姓名', prop: 'newVipName', minWidth: 80 },
          { label: '受邀人卡号', prop: 'newVipNo', minWidth: 80 },
          { label: '受邀人手机', prop: 'newVipTel', minWidth: 100 },
          { label: '受邀人奖品', prop: 'outQty', 
            formatter: (v, row) =>  {
              let obj = {
                1: '积分',
                2: '余额',
                3: '优惠券',
              }
              return  obj[row.newInviteRewardType]
            },
            minWidth: 80 },
          { label: '领取时间', prop: 'newVipOrderCreateTime', minWidth: 100 },
        ],
        list:[{}]
      }
    }
  },
  methods: {
    async handleEventDetail (type, row) {
      switch (type) {
        case 'close': // 关闭二级弹窗
          this.showList = true
        break
      }
    },
    async handleEvent(type, row) {
      console.log(type, row, 'type, row')
      const selectData = this.options.check;
      switch (type) {
        case 'close':
          this.showList = true
          this.optionsDetail.list = []
          break
        case 'oepnDetail': //获取点击商品信息
          if (this.options.check.length == 0) return this.$message.warning('请选择活动')
          this.optionsDetail.defaultBody.billId = this.options.check[0].billId
          this.showList = false
          break
        case "routerLink":
          {
            const billId = row.billId;
            this.$router.push({
              name: "InviteGiftDetail",
              query: {
                billId: billId,
                type: "Update",
              },
            });
          }
          break;
        case "add":
          {
            this.$router.push({
              name: "InviteGiftDetail",
              query: {
                type: "Add",
              },
            });
          }
          break;
        case "audit":
          {
            if (!selectData.length) return;
            const billId = selectData.filter(i => i.billStatus != 2).map((i) => i.billId)
            if (billId.length == 0) {return this.$message.warning('请选择未审核的数据')}
            inviteGiftAudit({ billIds: billId, auditStatus: 2}).then((res)=> {
              this.$message.success('审核成功')
              this.$nextTick(() => { this.options.check = [] })
              this.$refs.tablePage.getList();
            })
          }
          break;
        case "del":
          {
            if (!selectData.length) return;
            const billIds = selectData.map((i) => i.billId);
            inviteGiftDelete(billIds).then((res)=> {
              this.$message.success('删除成功')
              this.$nextTick(() => { this.options.check = [] })
              this.$refs.tablePage.getList();
            })
          }
          break;
        case "copy":
          {
            if (!selectData.length) return;
            const billIds = selectData.map((i) => i.billId);
            inviteGiftCopy(billIds).then((res)=> {
              this.$message.success('复制成功')
              this.$nextTick(() => { this.options.check = [] })
              this.$refs.tablePage.getList();
            })
          }
          break;
        case "recon":
          {
            if (!selectData.length) return;
            const billId = selectData.map((i) => i.billId);
            if (selectData[0].billStatus != 2) return  this.$message.warning('只能操作已审核的数据')
            inviteGiftUpdateIsEnable({ billIds: billId,isStop: false}).then((res)=> {
              this.$message.success('启用成功')
              this.$nextTick(() => { this.options.check = [] })
              this.$refs.tablePage.getList()
            })
          }
          break;
        case "redoRecon":
          {
            if (!selectData.length) return;
            const billId = selectData.map((i) => i.billId);
            if (selectData[0].billStatus != 2) return  this.$message.warning('只能操作已审核的数据')
            inviteGiftUpdateIsEnable({ billIds: billId,isStop: true}).then((res)=> {
              this.$message.success('禁用成功')
              this.$nextTick(() => { this.options.check = [] })
              this.$refs.tablePage.getList()
            })
          }
          break;
        default:
          break;
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.detail {
  ::v-deep .el-table {
    height: 500px !important;
    max-height: 500px !important;
    overflow: visible !important;
  }
}
</style>
